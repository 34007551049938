import { Select } from "view-design";
const zhTW = {
  network: {
    tips: `當前選定的網路不支援！請選擇 Matic（Polygon）網路！`,
    ev: "請檢查您是否安裝了錢包軟體，例如 MetaMask、Trust Wallet、imToken 或 TokenPocket。",
    copyight: process.env.VUE_APP_TITLE_NAME,
    all: "Max",
    bolck: "Dai 穩定幣",
    law2:`<center><h1>知情同意書</h1></center>
    <br>
    <p>我自願參與廣東賽爾生物科技有限公司（以下簡稱：賽爾集團）的細胞干預項目。在參與之前，我已經仔細閱讀並完全理解了以下內容，包括項目的背景、目的和相關權益。</p>
    <br>
    <h2>1. 細胞干預的目的：</h2>
    <p>此細胞干預項目旨在通過細胞干預改善我的健康狀況，觀察干預前後的變化。通過科學的細胞干預方式，希望我能夠在健康狀況上取得顯著的恢復與改善。</p>
    <br>
    <h2>2. 促進醫學發展：</h2>
    <p>在此細胞干預項目中，我與賽爾集團的治療團隊將共同擁有干預後的恢復成果。雙方將共享這些成果，以進一步推動相關醫學和科學領域的發展。</p>
    <br>
    <h2>3. 知情同意：</h2>
    <p>賽爾集團將擁有我在細胞干預過程中產生的所有資料，包括但不限於恢復記錄、病情改善記錄，以及面部特徵與其他器官改善的數據。我同意賽爾集團有權保存、解釋及利用這些資料，並在適當情況下發布相關研究成果或用於科學目的。</p>
    <br>
    <h2>4. 健康風險：</h2>
    <p>我參與此細胞干預項目完全是自願的。賽爾集團將採用科學的方法進行細胞干預；但由於個體差異，可能存在一定的風險和不確定性。賽爾集團已在項目開始前詳細解釋了這些風險，並將盡力保障我的安全和權益。</p>
    <p>我已被告知上述情況，理解其中可能存在的風險，並同意不對該過程提出合約爭議。我明確理解並接受以上內容，並同意賽爾集團對相關權益的擁有。</p>`,
    law: `<h3><strong style="color:yellow">此 DAPP 在某些國家和地區不受法律保護，請謹慎使用！</strong></h3>
    <br/>
    <p><strong>親愛的用戶：</strong></p>
    <br/>
    <p>感謝您對此 DAPP 的關注和支持。為了保障您的合法權益並幫助您了解相關法律風險，我們特此發布以下重要公告：</p><br/>
    <p>此 DAPP 涉及加密貨幣的投資和交易，可能會違反某些國家和地區的法律。根據當地監管機構的法律、法規和政策，此 DAPP 在某些國家和地區無法合法運營，因此我們聲明如下：</p>
    <br/>
    <h3><strong>1. 服務範圍限制：</strong></h3><br/>
    <p style="color:yellow">此 DAPP 不提供加密貨幣投資與交易服務，也不提供任何技術支持或售後服務於以下國家和地區：</p><br/>
    <p style="color:yellow">中國（包括中國大陸、香港特別行政區、澳門特別行政區和台灣）、印度、阿爾及利亞、孟加拉國、摩洛哥、埃及、尼泊爾、巴基斯坦、卡塔爾、伊拉克、突尼西亞、阿富汗、玻利維亞。</p>
    <br/> <strong>我們建議以上國家和地區的用戶立即停止使用此 DAPP，以避免可能的法律風險。</strong>
    <br/><br/>
    <h3><strong>2. 法律與投資風險警示：</strong></h3><br/>
    <p>在上述國家和地區使用此 DAPP 進行加密貨幣投資和交易，可能導致嚴重的法律後果。我們的團隊不會對由此引發的任何法律責任承擔責任。此外，加密貨幣市場本身具有高度波動性和不可預測性，投資活動存在重大風險。請在決定是否繼續使用此 DAPP 進行投資和交易前，充分理解並確認相關風險。</p>
    <br/>
    <h3><strong>3. 用戶確認：</strong></h3><br/>
    <p>如果您繼續使用此 DAPP 進行加密貨幣投資和交易，將被視為您已經仔細閱讀並理解本公告內容，並確認您知悉並願意承擔可能產生的法律風險和投資風險。您所採取的任何行為將被視為自願承擔相關責任，運營團隊將不對因此產生的任何後果承擔責任。</p>
    <br/>
    <h3><strong>4. 運營團隊聲明：</strong></h3><br/>
    <p>此 DAPP 由 Bull Developer 團隊開發和運營。我們始終遵循合規原則，致力於為用戶提供高品質的服務。但由於某些國家和地區的特殊法律環境，我們無法在這些地區繼續提供與加密貨幣相關的服務。我們強烈建議所有用戶在使用此 DAPP 前，了解並遵守各自國家或地區的法律法規，以避免潛在的法律和投資風險。</p>
    <br/>
    <p><strong>再次感謝您的理解和支持。如果您有任何問題或需要進一步的幫助，請通過我們的官方網站與我們聯繫。</strong></p>
    <br/>
    <p><strong>致敬！</strong></p>
    <br/>
    <h3>Bull Developer 團隊</h3>
    <h4>11/16 2023 PM 14:20</h4>
    `,
    protocol: `此分佈式應用程序 (DApp) 存在不確定的投資風險。在使用前，請確保您已閱讀並完全理解風險提示和隱私政策。`,
    more: "更多",
    media: "相關社交媒體",
    stake: "2023 年 11 月 6 日全球開放，請關注我們的 Telegram 和 Twitter！",
    withdraw: "2023 年 11 月 6 日全球開放，請關注我們的 Telegram 和 Twitter！",
    Reinvest: "2023 年 11 月 6 日全球開放，請關注我們的 Telegram 和 Twitter！",
    Getreward: "2023 年 11 月 6 日全球開放，請關注我們的 Telegram 和 Twitter！",
    linkwallet: "連接錢包",
    notification: "Falcon 燒毀加速公開測試已成功結束，接下來我們將部署正式合約，敬請期待！",
    notificationwith:"Bull Fund 將於 2024 年 10 月 25 日開始處理待提現的資產！每週處理一次，請隨時關注！",
    flashloansOK:'啟動成功！',
    flashloansNo:'啟動失敗！',
    withdrawinfo:"資產正在清算中，請等待清算完成後再驗證您的資產！",
    flashloansOnce:'恭喜您獲得一次體驗機會！',
    flashloansError:'抱歉，體驗機會已用完！',
    falocnerror:'未授予 Falcon™ V2 測試資格！',
    falocninfo:'Falcon™ V2 已通過 V3 級別玩家認可！目前處於公開測試階段！',
    falconv4:'Falcon™V4 套利集群正在進行測試（每日 28000 次）！'
  },
  leavel1: {
    0:"恐龍蛋",
    1: "棘龍",
    2: "劍龍",
    3: "翼龍",
    4: "三角龍",
    5: "長頸龍",
    6: "迅猛龍",
    7: "霸王龍",
    8: "三角龍",
    9: "三角龍",
    10: "三角龍"
  },
  leavel2: {
    0:"種子",
    1: "黑加侖",
    2: "车厘子",
    3: "火龍果",
    4: "榛子",
    5: "諾麗果",
    6: "牛心柿",
    7: "松露",
  },
  leavel: {
    0:"V0",
    1: "V1",
    2: "V2",
    3: "V3",
    4: "V4",
    5: "V5",
    6: "V6",
    7: "V7",
  },
  top: {
    Account: "帳戶",
  },
  swap:{
    Swap:"兌換",
    paynameA:"付款",
    paynameB:"收款",
    Approve:"授權",
    launchSwap:"啟動交換",
    recommend:"推薦",
    swapError:"兌換發生錯誤",
    swapNetwork:"網絡發生錯誤",
  },
  stake: {
    info2:"激活系統需要支付 1 個 ALS。",
    ActiveAccount:"激活帳戶",
    Balance: "您的餘額",
    Total: "總質押",
    Appoove: "授權",
    Stake: "存入",
    Appooveing: "授權中",
    AuthorSuccessful: "授權成功",
    AuthorFailed: "授權失敗",
    Stakeing: "存入中",
    StakeSuccessful: "存入成功",
    StakeFailed: "存入失敗",
    StakeInfo: "不能少於：",
    Activeaccount: "激活賬戶",
    active: "激活",
    auto: "自動",
    manual: "手動",
    info: "請選擇您的投資計劃。",
    Demand: "活期存款",
    Fixed: "定期存款",
    Wallet: "您的錢包",
    Prompt: "最低投資為 100 USDT。",
    duration: "選擇您的質押期限。",
    durationInfo: "質押期限越長，每日收益越高。",
    Already: "已存入",
    investta: "選擇投資計劃。",
    wealth: "財富管理",
    stem: "幹細胞",
    return: "回歸",
    GIA: "基因免疫力檢測:$2,320",
    FFC: "F成纖維細胞（面部）:$5,490",
    TCell: "TCEL胸腺依賴淋巴細胞:$21,800",
    T10IC: "T10免疫力細胞:$23,200",
    ASEL: "ASEL自體脂肪胚胎幹細胞:$32,850",
    ASEL2: "ASEL+自體脂肪胚胎幹細胞:$49,380",
    agreeForm: "請仔細查看知情同意書。",
    returnValue: "回歸值",
    agree:"同意",
    exit:"返回",
    minValue:"最低投資金額為 100,000 USDT，最低存入期限為 30 天。"

  },
  menu: {
    burn:"燃燒",
    Home: "首頁",
    Language: "語言",
    Document: "文檔",
    Contract: "合約",
    Watch: "觀察",
    Coming: "即將發佈",
    EvDao: "社區治理",
    Flashloan: "啟動閃電貸",
    Developer:"開發者文檔",
    Launch:"啟動",
    xNumber:"剩餘",
    Falcon:"Falcon™",
    xTips:"自動處理",
    LogiV2:"Falcon™ V2",
    LogiV3:"Falcon™ V3 Pro",
    LogiV4:"Falcon™ V4",
    swap:"閃兌",
    Market:"市場",
    LogiV2pro:"Falcon™ V2 Pro",
    LogiV3tips:"Falcon™ V3全球公測開始（日請求1000~7000次），需授權相應的套利合約！",
    Appoove2:"自動",
    Ido:"Falcon認購",
    myIdo:"我的認購",
    interest:"收益",
    stake:"存入",
    Generate:"生成"
  },
  locked: {
    title: "鎖定資產",
    genetic: "基因產品",
    direct: "直接推薦",
    Aggregation: "聚合",
    Back: "返回"
},
  ido:{
    select: "選擇社區",
    flashmonkey: "閃電猴",
    flashdragon: "閃電龍",
    fashFalcon: "Falcon",
    powermonger: "寶龍",
    fashfalcon2: "FalconV2",
    other: "其它",
    Getmax: "獲取最大認購額度",
    nowbuy: "立即認購",
    mynumber: "我的認購總額",
    myrelease: "已釋放",
    waitrelease: "待釋放",
    get: "提取",
    FalconF: "閃電鷹F",
    FalconA: "閃電鷹A",
    organization: "機構",
    endTimes:'第一輪倒計時',
    getmax:'獲取最大額度',
    Authorization:"授權",
    Subscribe:"立即認購",
    okSubscribe:"已認購",
    message:"節點錯誤，請更換節點",
    end1:"第一輪已結束",
    needStart:"距離開始",
    getscusse:"獲取成功",
    geterror:"獲取失敗"

  },
  Withdraw: {
    forzen:"凍結資產來源於定期存款和禮金饋贈。",
    Availableassets: "可用資產",
    LockedAassets: "鎖定資產",
    waitwithdraw: "待提現資產",
    Withdraw: "提現",
    Withdrawable: "錢包",
    Earnings: "收益",
    Brokerage: "佣金金額",
    Reinvest: "再投資",
    Getreward: "領取獎勵",
    Processing: "處理中",
    unsuccessfule: "失敗",
    successful: "成功！",
    WithdrawInfo1: "請輸入提現金額",
    WithdrawInfo2: "數量不足",
    burning: "Falcon 燒毀",
    noBurn: "未燃燒",
    addBurn: "燃燒收益",
    addBurnCost: "成本",
    BurnCount: "餘額",
    BurnSpeed: "燃燒加速",
    needpay: "需支付",
    startBurn: "啟動燃燒",
    Burning: "燃燒中",
    noBurn1: "已暫停",
    cangetfalcon: "預計可提取",
    stakedassets: "質押資產",
    updateInformation: "更新資訊",
    WithdrwaInfo: "鎖定的資產來源於產品或分期釋放的獎勵。",
    WithdrwaInfo3: "鎖定的資產來源於映射和定期存款。",
    WithdrawInfo2: "正在燃燒加速或映射中的本金為鎖定狀態，提現服務費為2%。",
    WithdrawInfo4:"提取本金免除服務費，提取利潤扣除 2% 服務費。",
    basicEarnings: "基本收益",
    ecosystemRewards: "生態系統獎勵",
    EarningsTitle: "收益詳情",
    Lockedassetsinfo: "此選項將凍結所有本金（不包括收益部分）。",
    lockedassets: "鎖定資產",
    Mapping: "映射",
    FixedDeposits: "定期存款",
    Back: "返回"
  },
  Team: {
    innver: "邀請",
    Teamstaked: "生態",
    leavel: "榮譽",
    Team: "生態",
    Bind: "綁定關係",
    info: "系統暫停綁定，請等待開放！",
    Binding: "綁定中",
    BindingSuccessful: "綁定成功",
    BindingFailed: "綁定失敗",
    Bindinfo: "请输入邀请人钱包地址",
  },
  Share: {
    Invitelink: "邀請鏈接",
    Intips: `目前 Dapp 邀請功能尚不可用。
            請等待最新的社區更新。`,
    copyTitle: "邀請鏈接",
    copyText: "邀請鏈接已成功複製！",
    copyCommnad: "複製鏈接",
    sharetitle: "分享",
    big: "Big",
    small: "Small",
    Honor: "榮譽",
    Team: "分享"
  },
  Contract: {
    contractaddress: "合約地址",
    Copylink: "Polygonscan",
    CopylinkText: "前往polygonscan",
  },
  Wathch: {
    watchonlywallet: "僅觀看錢包",
    addwallet: "添加觀察錢包",
    add: "添加",
    Leavel: "榮譽",
    share: "分享",
    fnOpen: "暫未邀請此錢包參與此功能！",
    nullString: "請輸入錢包地址！",
    addok:"添加成功",
    addError:"添加失败",
    addisExists:"此地址己存在",
    addlist:"觀察列表",

  },
  liquidity: {   
    menu: "添加流動性",
    title: "添加流動性",
    ADD: "添加",
    Flashloan: "閃電貸",
    SuperMember: "尊享會員",
    BalanceSavings: "餘額寶",
    Rewards: "獎勵",
    DepositeAmount: "存入金額",
    RemovingLiquidity: "移除流動性",
    LPProportion: "LP 占比",
    LPWeightedDividend: "LP 加權分紅",
    LPtop: "LP 頂部",
    or: "或",
    AddedDuration: "累計時長",
    remo: "撤池子：目前僅支持 100% 完全撤出。"
},
  flashloans:{
    tips: "請指定 Swift 的數量並啟動閃電貸款。",
    tips2:"Falcon V4 引擎將被用於啟動閃電貸款。",
    YourBalance:"啟動次數（當天）",
    numer:"請選擇每天啟動的次數。質押 1000 枚 Falcon 可以每天啟動 10 次，以此類推。",
    AlreadyStaked:"已經質押",
    WalletBalance:"錢包餘額",
    wait:"等待開放"
  },
  burn:{
    title: "燃燒加速",
    Acceieration: "加速",
    Rewards: "獎勵",
    AlreadyStaked: "已質押",
    MaximumBurn: "最大燃燒",
    BurnEligibility: "燃燒資格",
    AddedLPProportion: "新增 LP 比例",
    noLP: "未新增",
    yesLP: "已新增",
    swift: "Swift",
    Start: "開始加速",
    Burncosts: "燃燒成本",
    AlreadyRecovered: "已回收",
    BurnRewards: "燃燒獎勵",
    FalconEconsysteam: "Falcon 生態系統",
    burntime: "燃燒成本將在 24 小時後釋放。",
    Estimated: "預估",
    end: "結束",
    GetReward: "領取獎勵",
    SwiftEconsysteam: "Swift 生態系統",
    flashloan: "此功能正在維護中。",
    tips: `當前選定的網路不支援！請選擇 BSC 網路！`,
  },
  Governance: {
    lockTips:"本金鎖定: ",
    lockTipsend:"後自動解除鎖定。",
    InProgress: "進行中",
    Congratulations: "恭喜您獲得：第",
    QuotaReleasePackage: "輪額度釋放包！",
    YourDeposit: "您的存入",
    AlreadyMapped: "已經映射",
    MappingProportion: "映射占比",
    MappingStatus: "映射狀態",
    Provide: "提供映射的錢包",
    title: "治理",
    StartMapping: "開始映射",
    locktime: "一旦開始映射，本金將鎖定30天（利潤不鎖定）。重複映射將覆蓋鎖定時間。",
    tips: "該計劃旨在快速推進映射過程，並將根據最大容量釋放額度。",
},
crowdfunding: {
  menu: "眾籌",
  tips: "眾籌投資者最終將獲得與Falcon基金會簽訂的投資回報。",
  AlreadyStaked: "已質押",
  withdrawable: "可提取收益",
  totalwithdrawn: "總提取金額",
  Authorize: "授權",
  Withdraw: "提取",
  tips2: "眾籌計劃將於2024年12月30日結束。",
},
Miner: {
  title: "AI套利礦機",
},
lockedassets:{
  "title": "鎖定資產",
    "FixedDeposit": "定期存款",
    "Giftcertificate": "禮品券",
    "DirectRecommendation": "直接推薦",
    "back": "返回",
    "Transfer": "劃轉"
},
generate:{
  "Expred": "過期",
  "BindCode": "綁定邀請碼",
  "enterinvitation": "請輸入邀請碼",
  "InvitationCode": "邀請碼",
  "YourInvitationCode": "您的邀請碼",
  "title": "生成邀請碼",
  "generateEligibility": "生成資格",
  "PaymentRequired": "需要支付",
  "tips": "生成邀請碼需要銷燒價值100USDT的FALCON,且保留期為48小時。"
}
};
export default zhTW;
