const ja = {
  network: {
    tips: `選択したネットワークは現在サポートされていません！
    Matic（Polygon）ネットワークを選択してください！`,
    ev: "MetaMask、Trust Wallet、imToken、TokenPocketなどのウォレットソフトウェアをインストールしているかどうかを確認してください。",
    copyight: process.env.VUE_APP_TITLE_NAME,
    all: "最大",
    bolck: "Dai ステーブルコイン",
    law2: `<center><h1>インフォームドコンセントフォーム</h1></center>
    <br>
    <p>私は広東省賽爾バイオテクノロジー有限公司（以下「賽爾グループ」）の細胞介入プロジェクトに自主的に参加します。参加する前に、プロジェクトの背景、目的、および関連する権利を含む以下の内容を注意深く読み、十分に理解しました。</p>
    <br>
    <h2>1. 細胞介入の目的:</h2>
    <p>この細胞介入プロジェクトは、細胞介入を通じて私の健康状態を改善し、介入前後の変化を観察することを目的としています。科学的アプローチを用いることで、私の状態が顕著に回復および改善することを期待しています。</p>
    <br>
    <h2>2. 医学発展の促進:</h2>
    <p>このプロジェクトにおいて、私と賽爾グループの治療チームは、細胞介入後の回復結果を共同で所有します。両者はこれらの結果を共有し、関連する医学および科学分野の発展をさらに促進します。</p>
    <br>
    <h2>3. インフォームドコンセント:</h2>
    <p>賽爾グループは、私の細胞介入プロセス中に生成されたすべての資料を所有します。この資料には、回復記録、状態改善記録、およびその他の器官や顔の特徴の改善データが含まれますが、これらに限定されません。私は、賽爾グループがこれらの資料を保存、解釈、利用する権利を持ち、適切な場合には、関連する研究結果を発表または科学目的で共有することに同意します。</p>
    <br>
    <h2>4. 健康リスク:</h2>
    <p>このプロジェクトへの参加は完全に自主的です。賽爾グループは、細胞介入に科学的な方法を採用しますが、個人差により特定のリスクや不確実性が存在する可能性があります。賽爾グループは、プロジェクト開始前にこれらのリスクについて詳細な説明を提供し、私の安全と権利を確保するために全力を尽くします。</p>
    <p>上記の状況を理解し、関連する潜在的なリスクを理解したうえで、このプロセスに関連する契約上の紛争を追求しないことに同意します。私はこの内容を明確に理解し、受け入れ、賽爾グループが関連する権利を所有することに同意します。</p>`,
    law: `<h3><strong style="color:yellow">このDAPPは特定の国と地域では法的に保護されていませんので、注意して使用してください！</strong></h3>
    <br/>
    <p><strong>親愛なるユーザーの皆様へ:</strong></p>
    <br/>
    <p>このDAPPにご関心とご支援をいただきありがとうございます。法的権利を確保し、関連する法的リスクを理解していただくために、以下の重要なお知らせを発表します：</p><br/>
    <p>このDAPPは暗号通貨の投資と取引を含み、特定の国や地域の法律に違反する可能性があります。地元の規制当局の法律、規則、およびポリシーに従い、このDAPPは一部の国や地域では合法的に運営することができないため、以下を宣言します：</p>
    <br/>
    <h3><strong>1. サービス提供範囲の制限:</strong></h3><br/>
    <p style="color:yellow">このDAPPは、以下の国および地域では暗号通貨投資および取引サービスを提供しておらず、技術サポートまたはアフターサービスも提供していません:</p><br/>
    <p style="color:yellow">中国（中国本土、香港特別行政区、マカオ特別行政区、台湾を含む）、インド、アルジェリア、バングラデシュ、モロッコ、エジプト、ネパール、パキスタン、カタール、イラク、チュニジア、アフガニスタン、ボリビア。</p>
    <br/> <strong>上記の国と地域にお住まいのユーザーには、このDAPPの使用を直ちに中止し、法的リスクを回避することを強くお勧めします。</strong>
    <br/><br/>
    <h3><strong>2. 法的および投資リスクに関する警告:</strong></h3><br/>
    <p>上記の国および地域でこのDAPPを暗号通貨の投資および取引に使用することは、重大な法的結果をもたらす可能性があります。私たちのチームは、そのような行動から生じる法的責任を負いません。さらに、暗号通貨市場自体は非常に不安定で予測不可能であり、投資活動には重大なリスクが伴います。このDAPPを使用して投資および取引を継続するかどうかを決定する前に、関連するリスクを完全に理解し、確認してください。</p>
    <br/>
    <h3><strong>3. ユーザーの承認:</strong></h3><br/>
    <p>このDAPPを暗号通貨の投資および取引に使用し続ける場合、このお知らせの内容を十分に読み、理解したと見なされ、発生する可能性のある法的および投資リスクを認識し、それを引き受ける意思があることを認めたと見なされます。</p>
    <br/>
    <h3><strong>4. 運営チームからの声明:</strong></h3><br/>
    <p>このDAPPはBull Developerチームによって開発および運営されています。私たちは常にコンプライアンスの原則を遵守しており、ユーザーに高品質なサービスを提供することを目指しています。しかし、特定の国や地域における特別な法的環境のため、これらの地域で暗号通貨関連のサービスを提供し続けることができません。このDAPPの使用前に、各国や地域の法律や規則を理解し、遵守することを強くお勧めします。</p>
    <br/>
    <p><strong>ご理解とご支援に改めて感謝申し上げます。ご質問やさらなるサポートが必要な場合は、公式ウェブサイトを通じてお問い合わせください。</strong></p>
    <br/>
    <p><strong>よろしくお願い申し上げます。</strong></p>
    <br/>
    <p><strong>Bull Developer チーム</strong></p>`,
    protocol: `この分散型アプリケーション（DApp）は、不確定な投資リスクを伴います。使用前に、リスク警告とプライバシーポリシーを十分に読み、理解してください。`,
    more: "もっと見る",
    media: "関連するソーシャルメディア",
    stake: "2023年11月6日、グローバルオープニング。TelegramとTwitterをフォローしてください！",
    withdraw: "2023年11月6日、グローバルオープニング。TelegramとTwitterをフォローしてください！",
    Reinvest: "2023年11月6日、グローバルオープニング。TelegramとTwitterをフォローしてください！",
    Getreward: "2023年11月6日、グローバルオープニング。TelegramとTwitterをフォローしてください！",
    linkwallet: "ウォレットを接続",
    notification: "Falconの燃焼加速公開テストが無事終了し、公式契約の展開に進みます。乞うご期待！",
    notificationwith: "Bull Fundは2024年10月25日から未処理の引き出しの処理を開始します！処理は週に一度行われますので、引き続きご注目ください！",
    flashloansOK: '起動成功！',
    flashloansNo: '起動失敗！',
    withdrawinfo: "資産が清算中です。清算が完了するまでお待ちください！",
    flashloansOnce: 'おめでとうございます！1回の体験のチャンスを獲得しました！',
    flashloansError: '申し訳ありません、体験の機会が使い切られました！',
    falocnerror: 'Falcon™ V2テスト資格が付与されていません！',
    falocninfo: 'Falcon™ V2がV3レベルで承認されたプレイヤーに提供されます！現在、公開ベータ版です！',
    falconv4: 'Falcon™V4アービトラージクラスターはベータテスト中です（1日28,000回）！'
},
leavel1: {
  0: "恐竜の卵",
  1: "スピノサウルス",
  2: "ステゴサウルス",
  3: "プテラノドン",
  4: "トリケラトプス",
  5: "ブラキオサウルス",
  6: "ヴェロキラプトル",
  7: "ティラノサウルス・レックス",
  8: "ティラノサウルス・レックス",
  9: "ティラノサウルス・レックス",
  10: "ティラノサウルス・レックス",
},
leavel2: {
  0: "種子",
  1: "カシス",
  2: "チェリー",
  3: "ドラゴンフルーツ",
  4: "ヘーゼルナッツ",
  5: "ノニ",
  6: "柿",
  7: "トリュフ",
},
  leavel: {
    0:"V0",
    1: "V1",
    2: "V2",
    3: "V3",
    4: "V4",
    5: "V5",
    6: "V6",
    7: "V7",
  },
  ido: {
    select: "コミュニティを選択",
    flashmonkey: "FlashMonkey",
    flashdragon: "FlashDragon",
    fashFalcon: "Falcon",
    powermonger: "PowerMonger",
    fashfalcon2: "FalconV2",
    other: "その他",
    Getmax: "最大を取得",
    nowbuy: "今すぐ購読",
    mynumber: "私のFalcon",
    myrelease: "リリース済み",
    waitrelease: "リリース待ち",
    get: "引き出し",
    FalconF: "FlashFalcon(F)",
    FalconA: "FlashFalcon(A)",
    organization: "組織",
    endTimes: "第1ラウンドのカウントダウン",
    getmax: "最大を取得",
    Authorization: "承認",
    Subscribe: "今すぐ購読",
    okSubscribe: "購読済み",
    message: "ノードエラー、ノードを変更してください",
    end1: "第1ラウンド終了",
    needStart: "開始までのカウントダウン",
    getscusse: "取得成功",
    geterror: "取得失敗"
  },
  top: {
    Account: "アカウント",
  },
  locked: {
      title: "ロックされた資産",
      genetic: "遺伝子製品",
      direct: "直接紹介",
      Aggregation: "集約",
      Back: "戻る"
  },
  stake: {
    Balance: "あなたの残高",
    Total: "合計ステーク",
    Appoove: "承認",
    Stake: "ステーク",
    Appooveing: "承認中",
    AuthorSuccessful: "承認成功",
    AuthorFailed: "承認失敗",
    Stakeing: "デポジット中",
    StakeSuccessful: "デポジット成功",
    StakeFailed: "デポジット失敗",
    StakeInfo: "以下にはできません：",
    Activeaccount: "アカウントをアクティブにする",
    active: "アクティブ化",
    auto: "自動",
    manual: "手動",
    info: "投資プランを選択してください。",
    Demand: "要求預金",
    Fixed: "固定預金",
    Wallet: "あなたのウォレット",
    Prompt: "最低投資額は100 USDTです。",
    duration: "ステーキング期間を選択",
    durationInfo: "ステーキング期間が長いほど、日々のリターンが高くなります。",
    Already: "すでにステーク済み",
    investta: "投資プランを選択。",
    wealth: "資産管理",
    stem: "幹細胞パッケージ",
    return: "リターンパッケージ",
    GIA: "GIA:$2,320",
    FFC: "F-FC:$5,490",
    TCell: "T-Cell:$21,800",
    T10IC: "T10-IC:$23,200",
    ASEL: "ASEL:$32,850",
    ASEL2: "ASEL+:$49,380",
    agreeForm: "簡単な同意書を確認してください。",
    returnValue: "リターン値"
  },
  swap: {
    Swap: "スワップ",
    paynameA: "支払い",
    paynameB: "受領",
    Approve: "承認",
    launchSwap: "スワップを開始",
    recommend: "おすすめ",
    swapError: "スワップエラー",
    swapNetwork: "ネットワークエラー",
  },
  menu: {
    stake: "ステーク",
    burn: "燃焼",
    Home: "ホーム",
    Language: "言語",
    Document: "ホワイトペーパー",
    Contract: "契約",
    Falcon: "Falcon™",
    Watch: "ウォッチ",    
    Coming: "近日公開",
    EvDao: "ガバナンス",
    Flashloan: "フラッシュローンを開始",
    Developer: "開発者ドキュメント",
    Launch: "起動",
    xNumber: "残り",
    xTips: "自動処理",
    LogiV2: "Falcon™ V2",
    LogiV3: "Falcon™ V3",
    LogiV4: "Falcon™ V4",
    swap: "スワップ",
    LogiV2pro: "Falcon™ V2+",
    LogiV3tips: "Falcon™ V3のグローバル公開テストが開始されました（1日のリクエスト数1000〜7000）、対応する裁定取引契約の承認が必要です！",
    Appoove2: "自動",
    Market: "市場",
    Ido: "早期サブスクリプション 第1ラウンド",
    myIdo: "私のFalcon",
    interest: "利益",
  },
  Withdraw: {
    Availableassets: "利用可能な資産",
    LockedAassets: "ロックされた資産",
    waitwithdraw: "引き出し待ちの資産",
    Withdraw: "引き出し",
    Withdrawable: "ウォレット",
    Earnings: "収益",
    Brokerage: "手数料額",
    Reinvest: "再投資",
    Getreward: "報酬を受け取る",
    Processing: "処理中",
    unsuccessfule: "失敗",
    successful: "成功！",
    WithdrawInfo1: "引き出し金額を入力してください",
    WithdrawInfo2: "数量が不足しています",
    burning: "FalconBurn",
    noBurn: "燃焼なし",
    addBurn: "燃焼収益",
    addBurnCost: "コスト",
    BurnCount: "残高",
    BurnSpeed: "燃焼加速",
    needpay: "支払いが必要",
    startBurn: "燃焼を開始",
    Burning: "燃焼中",
    noBurn1: "一時停止",
    cangetfalcon: "引き出し可能と予想される",
    stakedassets: "ステーク済み資産",
    updateInformation: "情報を更新",
    WithdrwaInfo: "ロックされた資産は、製品または分割払いでリリースされた報酬に由来します。",
    WithdrawInfo2: "燃焼加速またはマッピング中の元本はロック状態にあり、引き出し手数料は2%です。",
    WithdrwaInfo3: "ロックされた資産は、マッピングおよび定期預金に由来します。",
    basicEarnings: "基本収益",
    ecosystemRewards: "エコシステム報酬",
    EarningsTitle: "収益の詳細",
    Lockedassetsinfo: "このオプションはすべての元本（利益部分を除く）を凍結します。",
    lockedassets: "ロックされた資産",
    Mapping: "マッピング",
    FixedDeposits: "定期預金",
    Back: "戻る"
  },
  Team: {
    innver: "内部",
    Teamstaked: "チームのステーク済み",
    leavel: "名誉",
    Team: "チーム",
    Bind: "関係を結ぶ",
    info: "システムが一時的にバインドを停止しました。再開をお待ちください。",
    Binding: "バインド中",
    BindingSuccessful: "バインド成功",
    BindingFailed: "バインド失敗",
    Bindinfo: "招待者のウォレットアドレスを入力してください",
  },
  Share: {
    Invitelink: "招待リンク",
    Intips: `Dappの招待機能は現在利用できません。
            最新のコミュニティアップデートをお待ちください。`,
    copyTitle: "招待リンク",
    copyText: "招待リンクが正常にコピーされました！",
    copyCommnad: "リンクをコピー",
    sharetitle: "共有",
    big: "大",
    small: "小",
    Honor: "名誉",
    Team: "共有"
  },
  Contract: {
    contractaddress: "契約アドレス",
    Copylink: "Polygonscan",
    CopylinkText: "Polygonscan に移動！",
  },
  Wathch: {
    watchonlywallet: "閲覧専用ウォレット",
    addwallet: "ウォレットを追加",
    add: "追加",
    Leavel: "レベル",
    share: "共有",
    fnOpen: "このウォレットは、この機能に参加する招待を受けていません！",
    nullString: "ウォレットアドレスを入力してください！",
    addok: "正常に追加されました",
    addError: "追加に失敗しました",
    addisExists: "このアドレスはすでに存在します",
    addlist: "ウォッチリスト",
  },
  burn: {
    title: "燃焼",
    Acceieration: "加速",
    Rewards: "報酬",
    AlreadyStaked: "既にステーク済み",
    MaximumBurn: "最大燃焼",
    BurnEligibility: "燃焼資格",
    AddedLPProportion: "追加されたLPの割合",
    noLP: "追加されていません",
    yesLP: "追加済み",
    swift: "スウィフト",
    Start: "加速を開始",
    Burncosts: "燃焼コスト",
    AlreadyRecovered: "既に回収済み",
    BurnRewards: "燃焼報酬",
    FalconEconsysteam: "Falcon エコシステム",
    burntime: "燃焼コストは24時間後にリリースされます",
    Estimated: "推定",
    end: "終了",
    GetReward: "報酬を受け取る",
    SwiftEconsysteam: "Swift エコシステム",
    flashloan: "この機能はメンテナンス中です。",
    tips: `選択したネットワークは現在サポートされていません！
    BSCネットワークを選択してください！`,
  },
  liquidity: {
    menu: "流動性",
    title: "流動性を追加",
    ADD: "追加",
    Flashloan: "フラッシュローン",
    SuperMember: "スーパーメンバー",
    BalanceSavings: "残高貯蓄",
    Rewards: "報酬",
    DepositeAmount: "預入額",
    RemovingLiquidity: "流動性の削除",
    LPProportion: "LP割合",
    LPWeightedDividend: "LP加重配当",
    LPtop: "LPトップ",
    or: "または",
    AddedDuration: "追加された期間",
    remo: "プールのスキミング：現在、100%のみをサポートしています。",
  },
  flashloans: {
    tips: "Swiftの量を指定してフラッシュローンを開始してください。",
    tips2: "フラッシュローンの起動にはFalcon V4エンジンが使用されます。",
    YourBalance: "今日の起動回数",
    numer: "1,000 Falconをステーキングすると、1日10回の起動が可能です。それに応じて選択してください。",
    AlreadyStaked: "すでにステーク済み",
    WalletBalance: "ウォレット残高",
    burn: "燃焼"
  },
  Governance: {
    lockTips: "元本ロック: ",
    lockTipsend: "後に自動で解除されます",
    InProgress: "進行中",
    Congratulations: "おめでとうございます！受け取りました: 第",
    QuotaReleasePackage: "ラウンド クオータリリースパッケージ！",
    YourDeposit: "あなたの預入",
    AlreadyMapped: "すでにマッピング済み",
    MappingProportion: "マッピング割合",
    MappingStatus: "マッピング状況",
    Provide: "マッピング用のウォレットを提供",
    title: "ガバナンス",
    StartMapping: "マッピングを開始",
    locktime: "マッピングを開始すると、元本は30日間ロックされます（利益はロックされません）。繰り返しマッピングするとロック期間が上書きされます。",
    tips: "このプランはマッピングプロセスを迅速に進めることを目的としており、最大容量に基づいてクオータをリリースします。"
  },
  crowdfunding: {
    menu: "クラウドファンディング",
    tips: "クラウドファンディング投資家は、最終的にFalcon財団との合意に基づく投資収益を受け取ります。",
    AlreadyStaked: "すでにステーク済み",
    withdrawable: "引き出し可能な収益",
    totalwithdrawn: "総引き出し額",
    Authorize: "承認",
    Withdraw: "引き出し",
    tips2: "クラウドファンディング計画は2024年12月30日までに終了します。"
  },
  Miner: {
    title: "AIアービトラージマイナー",
  }
};
export default ja;
